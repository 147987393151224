// Menu generation script
/* globals getScannerUsers, searchListForItem, clearScanParameters, alert, saveProgramParameters, getSessionUserId,
   countStoredTransactions */
function buildMenu(prog,level,key) {
    "use strict";
    var lastMenu;
    if (typeof prog === "undefined") { 
        // build menu has been called from either login screen or the back button
        // check local storage to see if a previous menu has been saved.( used if back button has been hit)
        if(sessionStorage.getItem("runtime/lastMenu") !== null) {
            // last menu must exist, load values from there.
            lastMenu = sessionStorage.getObject("runtime/lastMenu");
            prog = lastMenu[0];
            level = lastMenu[1];
            key = lastMenu[2];
            // now that we have set the parameters delete the local storage object. The menu generation will create a new instance as appropriate.
					sessionStorage.removeItem("runtime/lastMenu");
        } else {
            // the local storage object doesn't exist, so we must be loading the app fresh. Go to main menu.
            prog = false;
            level = 1;
            key = 0;
        }
    }

    // otherwise params have been passed, load that menu.
    var dramscanUsers = getScannerUsers();
    var currentUser = searchListForItem(dramscanUsers,"id",getSessionUserId());
	  var menuContainer = $("#menuContainer"),
			fullMenu = [];

	  if (dramscanUsers[currentUser].hasOwnProperty('menu')) {
			fullMenu = dramscanUsers[currentUser].menu;
		} else {
			menuContainer.append('<br><div class="well">This user does not have a menu, or has no access to any programs.</div>');
			return;
		}

    // get current menu item based on key
        var menuIndex;
        for (var h = 0; h < fullMenu.length; h++) {
            if (fullMenu[h].iKeyID === key) {
             // program found
                menuIndex = h;
                break;
            }
        }
    
    if (prog === true) {
      // this is a program
      // redirect to the program page. no need to re-build a menu here
      // need to set local storage vars to re-access menu page with correct menu.
      // set last used menu key for a back button.
      // if menu option is change location then this needs to be a little different.
        
      // retrieve run name.
      var programLink = fullMenu[menuIndex].cRunPath;
      // if not set then stop and warn user.
      if(programLink === "") {
          alert("Program functionality not defined");
          return;
        }
      clearScanParameters();
      // go to run name link. Query the program ID to let the program set itself up.
      // write the program ID to session storage to use immediately.
      sessionStorage.setItem('runtime/navigation', fullMenu[menuIndex].iKeyID.toString());
      document.location.href='./' + programLink;
      return;
    }

    // Set the title for the menu based on where we are.
    if(level === 1) {
      $("#pageTitle").html("Menu");
    } else {
      $("#pageTitle").html(fullMenu[menuIndex].cLabel);
    }
    
    var currentMenu = [];
    var parentMenu = (menuIndex)? fullMenu[menuIndex].iParentID : 0;
    for (var i = 0; i < fullMenu.length; i++) {

            // user id match, menu item for current user
            if(fullMenu[i].iLevel === level) {
                // level same as input, correct menu level
                // next check parent is key
                if(fullMenu[i].iParentID === key) {
                    // parent matches key, correct sub menu 
                    // add menu entry to current menu - only if allowed to use option
                    if(fullMenu[i].lEnabled) {
                      currentMenu.push(fullMenu[i]);
                    }
                }
            }
    }
    if (currentMenu.length > 0) {
        // valid menu, store detail in the local storage for back operations
        lastMenu = [prog, level, key];
        sessionStorage.setObject("runtime/lastMenu",lastMenu);
    }

    // now that we have a current menu we must display it, remembering to pass in the callback function with parameters.
    // prog should be value of each item lProgram
    // level should be item iLevel
    // key should be iKeyID

    menuContainer.html("");
    var nextLevel;
    for (var j = 0; j < currentMenu.length; j++) {
        nextLevel = currentMenu[j].iLevel + 1;
        // create menu item
        var extraButtonCode = (currentMenu[j].lProgram) ? '' : '<span class="glyphicon glyphicon-arrow-right menu-option"></span>';

        menuContainer.append('<button class="btn btn-dramscan btn-menu btn-lg btn-block " tab-index="'+ j +'" ' +
            'onClick="buildMenu(' + currentMenu[j].lProgram + ',' + nextLevel + ',' + currentMenu[j].iKeyID + ',' + currentMenu[j].iParentID + ')">' +
            '<span class="badge">'+(j+1)+'</span>'+ currentMenu[j].cLabel + extraButtonCode + '</button> ');
    }
    // if level not 1 then we need a back button.
    if(nextLevel > 2) {
        var prevlevel = nextLevel - 2;
      menuContainer.append("<button class='btn btn-dramscan btn-menu btn-lg btn-block' tab-index='" + j + "' onClick='buildMenu(false,"+prevlevel+","+parentMenu+")'><span class='badge'>"+(j+1)+"</span><span class='glyphicon glyphicon-arrow-left'></span> Back</button>");
    }
    // if level 1 then we want to show the transaction count.
    if(nextLevel === 2) {
      menuContainer.append("<br><input type=\"text\" data-theme=\"h\" class=\"form-control input-lg\" id=\"transactioninfo\" readonly>");
        //$("#transactioninfo").val("Transactions: " + countStoredTransactions());
        countStoredTransactions(function(count) {
          $("#transactioninfo").val("Transactions: " + count);
        });

    }
    if(typeof nextLevel == "undefined") {
      menuContainer.append('<br><div class="well">No options Available.</div>' +
        '<button class="btn btn-dramscan btn-lg btn-block" onclick="window.location.reload()">Main Menu</button>');
    }
    fullMenu = null;
    nextLevel = null;
    parentMenu = null;
    currentMenu = null;
    menuContainer = null;

}

// Menu Utilities
/**
 * @description Attaches keyboard shortcuts for numeric keypad to menu items
 */
var attachMenuNumbers = function() {
	"use strict";
    // Menu keypad buttons
    // Different devices need to use different events. keypress is deprecated but we need to use it in Zetakey.
  $(document).on(getKeyEventName('menuNumbers'),function(e) {
    var key = e.keyCode;
    if((key >= 49 && key <= 57) || (key >= 97 && key <= 105)) {
      var number = String.fromCharCode(key);
      number --;
      var selector = "button[tab-index=" + number + "]";
      var button = $(selector);
      button.addClass("clicked");
      if(button.text()) {
        button.click();
      }
    }
  });
};

/**
 * @description Removes keyboard shortcuts to menu items
 */
var detachMenuNumbers = function() {
	"use strict";
    // Different devices need to use different events. keypress is deprecated but we need to use it in Zetakey.
    var eventName = (isAndroid) ? 'keydown.menuNumbers' : 'keypress.menuNumbers';
    $(document).off(eventName);
};